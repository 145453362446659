import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../../../components/Layout/Layout"
import Article from "../../../components/Article/Article"
import CallToAction from "../../../components/Article/CallToAction"
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs"
import i18n from "i18next"

const SeoArticle = ({ data }) => {
  useEffect(() => {
    i18n.changeLanguage("pl")
  }, [])
  return (
    <Layout
      seo={{
        title: "CMS. Czy wybór systemu zarządzania treścią może mieć wpływ na SEO?",
        description:
          "Dowiedz się, do czego służą systemy CMS, jakie są ich najpopularniejsze rodzaje i na co zwrócić uwagę przy ich wyborze, aby skutecznie wspierać działania SEO.",
        schema: {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              item: {
                "@id": "/",
                name: "Główna",
              },
            },
            {
              "@type": "ListItem",
              position: 2,
              item: {
                "@id": "/baza-wiedzy/artykuly/",
                name: "Baza wiedzy",
              },
            },
            {
              "@type": "ListItem",
              position: 3,
              item: {
                "@id":
                  "/baza-wiedzy/artykul/cms-czyli-system-zarzadzania-trescia-czym-jest-do-czego-sluzy-i-jak-wplywa-na-seo/",
                name: "CMS. Czy wybór systemu zarządzania treścią może mieć wpływ na SEO?",
              },
            },
          ],
        },
      }}
    >
      <Breadcrumbs
        path={[
          { url: "/", text: "Główna" },
          {
            url: "/baza-wiedzy/artykuly/",
            text: "Baza wiedzy / Artykuły",
          },
          {
            url: "/baza-wiedzy/artykul/cms-czyli-system-zarzadzania-trescia-czym-jest-do-czego-sluzy-i-jak-wplywa-na-seo",
            text: "CMS, czyli system zarządzania treścią. Czym jest, do czego służy i jak wpływa na SEO?",
          },
        ]}
      />
      <Article
        img={
          <img
            src={data.file.childImageSharp.fluid.src}
            alt={"CMS, czyli system zarządzania treścią. Czym jest, do czego służy i jak wpływa na SEO?"}
          />
        }
      >
        <h1>CMS, czyli system zarządzania treścią. Czym jest, do czego służy i jak wpływa na SEO?</h1>
        <p>
          <strong>
            Z czasem tworzenie treści internetowych staje się coraz prostsze i nie wymaga posiadania specjalnej wiedzy
            informatycznej. Jednym ze sztandarowych przykładów tej tendencji jest bez wątpienia CMS, czyli system
            zarządzania treścią, który znacznie ułatwia laikom tworzeń stron WWW i sklepów internetowych. Dowiedz się,
            czym właściwie jest CMS, jakie są jego rodzaje i czy wpływa na SEO.
          </strong>
        </p>
        <h2>Co to jest CMS i co umożliwia?</h2>
        <p>
          CMS to skrót od angielskiej nazwy Content Managment System, tłumaczonej na polski najczęściej jako „system
          zarządzania treścią”. Za tą nazwą kryje się oprogramowanie, które ułatwia tworzenie i rozwijanie serwisu WWW,
          czyli dodawanie wszelkich treści i ich edytowanie, publikowanie grafik, tabel, filmów i innych elementów
          dostępnych na stronach internetowych.
        </p>
        <p>
          CMS to przydatne rozwiązanie dla osób, które chcą samodzielnie tworzyć własny serwis WWW, ale nie posiadają
          wiedzy na temat programowania. Serwisy zarządzania treścią dają wiele możliwości nie tylko w kontekście
          dodawania treści, ale również kreowania witryn internetowych od strony estetycznej i funkcjonalnej za pomocą
          specjalnych dodatków i wtyczek.{" "}
        </p>
        <p>
          To znaczy, że użytkownik ma wpływ na to, czy np. na jego stronie pojawi się możliwość dodawania komentarzy,
          które będzie mógł samodzielnie moderować. Ponadto niektóre CMS-y wspierają twórcę strony w trudnych kwestiach
          związanych z SEO.{" "}
        </p>
        <p className="inserted-seo-link">
          Dowiedz się,{" "}
          <a
            href="https://blog.wenet.pl/seo-pozycjonowanie/czym-jest-audyt-seo-dlaczego-jest-potrzebny-wlascicielom-stron-www/"
            target="_blank"
            rel="noopener, noreferrer"
          >
            czym jest audyt SEO
          </a>{" "}
          strony? Sprawdź!
        </p>
        <p>Najbardziej cenionymi i pożądanymi przez użytkowników cechami CMS-ów są: </p>
        <ul>
          <li>łatwość obsługi, </li>
          <li>wielość funkcji, </li>
          <li>brak ograniczeń technicznych,</li>
          <li>łatwe w obsłudze wsparcie SEO.</li>
        </ul>
        <p>
          Przy wyborze odpowiedniego CMS-u, warto brać pod uwagę przede wszystkim wymagania, jakie będą stawiane przed
          planowaną stroną internetową, tak aby wybrane oprogramowanie sprostało oczekiwaniom twórców i użytkowników.{" "}
        </p>
        <CallToAction
          to={"/"}
          text={"Wykonaj darmowy audyt strony i popraw jej widoczność w wyszukiwarce. Wykonaj teraz >>"}
        />
        <h2>Systemy CMS – 2 najpopularniejsze rodzaje</h2>
        <p>
          Systemów zarządzania treścią jest naprawdę wiele; jedne dedykowane są osobom prywatnym, inne firmom, niektóre
          są darmowe, a za inne trzeba zapłacić.{" "}
        </p>
        <p className="inserted-seo-link">
          Wykonaj <a href="/">audyt SEO strony</a> i popraw jej widoczność w wyszukiwarce.
        </p>
        <p>
          Do najpopularniejszych CMS-ów należą m.in.: WordPress, PrestaShop, Joomla, Google Web Sites Creator, Shopify,
          OpenText czy Adobe Experince Manager Site.{" "}
        </p>
        <p>
          Aby odnaleźć się w tym bujnym gąszczu możliwości, postanowiliśmy przedstawić 2 najpopularniejsze rodzaje
          CMS-ów. Podział przebiega pomiędzy oprogramowaniem z zamkniętym i otwartym kodem źródłowym. Powinno to nieco
          rozjaśnić sytuację przyszłym twórcom serwisów WWW i ułatwić wybór wariantu dla siebie.{" "}
        </p>
        <ul>
          <li>
            <strong>CMS z zamkniętym kodem źródłowym</strong> – w tym przypadku kod źródłowy jest znany jedynie klientom
            danej firmy. Ma to swoje plusy, ponieważ wspiera bezpieczeństwo. Jednak relatywnie mała grupa użytkowników
            nie jest w stanie rozwinąć zbyt wielu dodatków i zainicjować modyfikacji, a także ma ograniczone możliwości
            w wykrywaniu błędów w kodzie źródłowym.{" "}
          </li>
          <li>
            <strong>CMS z otwartym kodem źródłowym (Open Source)</strong> – rozwiązanie znacznie popularniejsze od
            poprzednika, stanowiące właściwie jego przeciwieństwo. Duża liczba użytkowników sprawia, że mamy do
            czynienia z dynamicznie rozwijającym się systemem, bogatym w różnego rodzaju wtyczki, szablony itp., a także
            aktywnym w odnajdowaniu i korygowaniu powstałych błędów w kodzie źródłowym. Niestety, publicznie dostępny
            kod źródłowy sprawia, że system CMS Open Source podatny jest na ataki hakerów.{" "}
          </li>
        </ul>
        <p>
          Warto wspomnieć również o tzw. CMS-ach „hostowanych”, które nie cieszą się w środowisku dobrą sławą. W tym
          przypadku nawet użytkownicy nie mają dostępu do kodu źródłowego, a cały system nie oferuje zbyt wielu
          możliwości, jeśli chodzi o dodatki i modyfikacje. Ponadto CMS hostowany często gwarantuje darmowy hosting, w
          zamian wyświetlając na stronach reklamy usługodawcy.{" "}
        </p>
        <h2>Jak system CMS wpływa na SEO?</h2>
        <p>
          Algorytmy Google nie odróżniają strony stworzonej w systemie CMS od tej napisanej w języku HTML. Jednak bez
          wątpienia odnotowują różnice w czasie wczytywania się strony, jeśli takowe istnieją.{" "}
        </p>
        <p>
          Większy wpływ na pozycje strony lub sklepu internetowego w wynikach wyszukiwania ma to, w jaki sposób CMS
          generuje adresy URL. Niewłaściwa budowa URL może spowodować obniżenie pozycji witryny, a nawet zupełnie
          uniemożliwić jej pozycjonowanie. Dlatego wybór odpowiedniego systemu CMS może okazać się kluczową decyzją dla
          powodzenia danego serwisu.{" "}
        </p>
        <p>
          Aby mieć pewność, że wybrany system CMS jest „seo friendly”, warto sprawdzić, czy posiada odpowiednie funkcje,
          wtyczki i moduły wspierające SEO, np. takie jak:
        </p>
        <ul>
          <li>optymalizacja metadanych dla poszczególnych podstron serwisu,</li>
          <li>automatyczne generowanie sitemap oraz robots.txt,</li>
          <li>hierarchiczna organizacja treści,</li>
          <li>tworzenie odpowiednich adresów URL,</li>
          <li>optymalizacja grafik,</li>
          <li>obsługa błędów 404,</li>
          <li>motywy przystosowane do urządzeń mobilnych.</li>
        </ul>
        <p>
          Wybór najlepszego systemu CMS w kontekście SEO ma niemałe znaczenie, ponieważ pozwala zaoszczędzić czas i
          pieniądze przeznaczone na wynajęcie specjalisty od SEO, a przede wszystkim wpływa na wysoką pozycję strony lub
          e-sklepu w wynikach wyszukiwania.{" "}
        </p>
      </Article>
    </Layout>
  )
}

export const query = graphql`
  {
    file(relativePath: { eq: "img/full/cms.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1100, jpegQuality: 90) {
          sizes
          src
        }
      }
    }
  }
`

export default SeoArticle
